.product-card-container {
  //background: #E0E1BE;
  // background: #f6f6d3;
  background: #161320;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 234px;
  user-select: none;
.product-card-img{
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  cursor: pointer;
}
  img {
    width: 234px;
    height: 234px;
    //height: 168px;
    object-fit: cover;
  }

  .product-slider {
  position: absolute;
    margin-top: 216px;
  }

  .product-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // color: #161320;
    height: 100%;
    justify-content: space-between;
  }

  .product-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // color: #161320;
    height: 100%;
    justify-content: space-between;

    .product-card-lower {
      padding: 0 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .product-card-title {
      font-size: 12px;
      font-weight: 600;

    }

    .product-card-price {
      font-size: 24px;
      font-weight: 700;
    }

    .product-card-button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      background-color: #0e6e4f;
      color: white;
      font-size: 14px;
      font-weight: 500;
      height: 28px;
      cursor: pointer;

      &:hover {
        background-color: rgb(195, 241, 15);
        color: #161320;
      }
    }

    .product-card-info {
      border-top: 1px solid #16132029;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //    background-color: white;
      padding: 8px;

      .likes {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .delivery {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .comments {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}