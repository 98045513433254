.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  //  background: #161320;
  color: #EFF1F1;
  line-height: 102%;
  min-height: 100vh;
  background: #161320 url('img/bg-1.png') no-repeat center center fixed;
  background-size: cover;
  animation: 60s changeBg infinite;

}

@keyframes changeBg {
  0% {
    background-image: url('img/bg-1.png');
  }
  20% {
    background-image: url('img/bg-1.png');
  }
  25% {
    background-image: url('img/bg-2.png');
  }
  45% {
    background-image: url('img/bg-2.png');
  }
  50% {
    background-image: url('img/bg-3.png');
  }
  70% {
    background-image: url('img/bg-3.png');
  }
  75% {
    background-image: url('img/bg-4.png');
  }
  95% {
    background-image: url('img/bg-4.png');
  }
  100% {
    background-image: url('img/bg-1.png');
  }
}

.logo-icon {
  height: 68px;
  min-width: 120px;
  grid-area: logo;
}

.main-container {
  max-width: 1920px;
  display: contents;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  width: 100%;
  max-width: 1032px;
  padding: 24px;
  background: rgba(50, 54, 56, 0.7);
  h3{
    margin: unset;
    font-weight: 500;
  }
}
.content{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

header {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "logo search buttons";
  gap: 24px;
  justify-items: center;
}

footer {
  background: rgba(50, 54, 56, 0.7);
  padding: 24px;
  width: 100%;
  max-width: 1032px;
  margin-top: 24px;
}

.search-container {
  grid-area: search;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1080px;
  //  max-width: 1080px;
  gap: 12px;
}

.search-bar {
  width: 100%;
  height: 40px;
  background: rgba(84, 200, 237, 0.15);
  border-radius: 4px;
  border: 2px solid rgba(239, 241, 241, 0.45);
  padding: 0 20px;
  font-size: 16px;
  outline: none;
  color: #EFF1F1;
  display: flex;
  align-items: center;

}

button {
  background: #EFF1F1;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #161320;
  cursor: pointer;
  outline: none;
  height: 40px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 102%;
  display: flex;
  align-items: center;

  &.header-button {
    font-size: 20px;
    font-weight: 500;
  }

  .primary {

  }

  &.secondary {
    background: transparent;
    color: #EFF1F1;
    border: 2px solid #E4E9E8;
    box-sizing: border-box;
  }

}

.button-container {
  display: flex;
  gap: 10px;
  align-items: center;
  grid-area: buttons;
}
.footer{
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    .footer-links{
      display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }
}
@media (max-width: 1680px) {
  header {
    grid-template-areas: "logo buttons"
                             "search search";
    grid-template-columns: 1fr 1fr;
    .logo-icon{
      justify-self: start;
      width: fit-content;

    }
    .button-container{
      justify-self: end;
    }
    margin-bottom: 12px;
  }
}