.categories{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 12px 0;

}

.category{
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 4px;
    background-color: #5a595f;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.4s;
    &:hover{
        background-color: #414049;
    }
}
.games-filter{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.products{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}